.banner{
	.collapsing, .collapse, .in{
    		min-height: 400px;
    		background-color: #fff;
    	}
    .container{
    	padding-right: 15px;
    	padding-left: 15px;
    }
	.navbar{
		height: 130px;
    	padding-top: 50px;
    	background-color: #fff;
		.brand {
			margin-left: 15px;
    		display: block;
    		width: 200px;
		}
		#navbar{
			margin-top: 29px;
			.menu-kontakt{
				background-color: $infinitus-red;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-top: 10px;
				margin-left: -15px;
				display: inline-block;
				a{
					color: #fff;
					display: inline-block;
					&:hover{
						background-color: $infinitus-red;
					}
				}
			}
			.phone {
			    margin-bottom: 35px;
			    text-align: right;
			}
			li{
				display: inline;
				padding: 15px;
				a{
					display: block;
					padding: 0px;
					color: $infinitus-dark-grey;
					&:hover{
						background-color: #fff;
						border-bottom: 1px $infinitus-red !important;
					}
				}
			}
			.active{
				a{
					border-bottom: 0px solid $infinitus-red;
				}
			}
		}
	}
}

.header-bild{
		position: relative;
		height: 450px;
		background-size: cover!important;
		background-position: center center!important;
		background-repeat: no-repeat!important;

	.header-overlay{
		position: relative;
		width: 100%;
		height: 450px;
		background-color: rgba(227, 79, 67, 0.9);
		.captition{
			margin-top: 190px;
			h1{
				color: #fff;
				font-weight: bold;
			}
			p{
				color: #fff;
				font-size: 20px;
    			font-weight: lighter;
			}
			.produktslider{
				width: 320px;
				margin-top: -50px;
				float: right;			
				.item{
					img{
						width: 100%;
						height: auto;
						border: 1px solid $infinitus-light-grey;
						width: 320px;
						height: 320px;
						background-color: rgba(255, 255, 255, 0.5);
					}
					.carousel-caption{
						bottom: 50px;
						h3{
							a{
								color: $infinitus-light-grey;
								text-shadow: none;
								text-decoration: none;
							}
						}
					}
				}
			}
			.abgrenzung{
				border-right: 0px solid #fff;
			}
		}
	}
}
.header-unterseite{
		@include center();
		h1{
			text-align: center;
			color: #fff;
			font-size: 36px;
			font-weight: 700;
			margin-top: 220px;
		}
		.subline{
			color: #fff;
		}
}


/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 

.banner{
	.collapsing, .collapse, .in{
    		min-height: 400px;
    		background-color: #fff;
    	}
	.navbar{
		.brand{
			margin-left: 0px;
		}
		#navbar{
			float: right;
			min-height:100px;
			margin-top: -25px;
			.menu-kontakt{
				background-color: $infinitus-red;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-left: 15px;
				display: inline-block;
				margin-top: 0px;
				a{
					color: #fff;
					display: inline-block;

				}
			}
			.phone {
			    margin-bottom: 35px;
			    text-align: right;
			}
			li{
				display: inline;
				padding: 15px;
				a{
					display: inline-block;
					padding: 0px;
					color: $infinitus-dark-grey;
					&:hover{
						background-color: #fff;
						border-bottom: 1px $infinitus-red !important;
					}
				}
			}
			.active{
				a{
					border-bottom: 2px solid $infinitus-red;
				}
			}
		}
	}
}


.header-bild{
		position: relative;
		height: 750px;
		background-size: cover!important;
		background-position: center center!important;
		background-repeat: no-repeat!important;

	.header-overlay{
		position: relative;
		width: 100%;
		height: 750px;
		background-color: rgba(227, 79, 67, 0.9);
				.captition{
			margin-top: 24%;
			h1{
				color: #fff;
				font-weight: bold;
			}
			p{
				color: #fff;
				font-size: 30px;
    			font-weight: lighter;
			}
			.produktslider{
				width: 320px;
				margin-top: -50px;
				float: right;			
				.item{
					img{
						width: 100%;
						height: auto;
						border: 1px solid $infinitus-light-grey;
						width: 320px;
						height: 320px;
						background-color: rgba(255, 255, 255, 0.5);
					}
					.carousel-caption{
						bottom: 50px;
						h3{
							a{
								color: $infinitus-light-grey;
								text-shadow: none;
								text-decoration: none;
							}
						}
					}
				}
			}
			.abgrenzung{
				border-right: 1px solid #fff;
			}
		}
		
			
		}
	}

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 

}

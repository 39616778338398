.footer{
	height: 500px;
	background-color: $infinitus-red;
	color: #fff;
	padding-top: 100px;
	a{
		color: #fff;
	}
	h2{
		font-weight: lighter;
		color: #fff;
	}
}
.footer-bottom{
	a{
		display: inline;
		color: $infinitus-dark-grey;
	}
	li{
		display: inline;
		float: left;
	}
}
.cta-header {
    color: #ffffff;
    font-size: 20px;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    width: 100%;
    border: 2px solid #fff;
    display: block;
}
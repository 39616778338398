body{
	font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

h2 {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    color: $infinitus-dark-grey;
}

p{
    color: #959595;
}

.section{
    padding-top: 100px;
    padding-bottom: 150px;
}

.subline{
    font-size: 27px;
    font-weight: lighter;
    text-align: center;
    color: $infinitus-grey;
    margin-bottom: 30px;
}

.led-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 40px;
}

@mixin center($x: true, $y: true) {
    position: absolute;

    $yPos: 0;
    $xPos: 0;

    @if $x {
        $xPos: -50%;
        left: 50%;
    }

    @if $y {
        top: 50%;
        $yPos: -50%;
    }

    transform: translate($xPos, $yPos);
    transform: translate3d($xPos, $yPos, 0);
}




/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 

.subline{
    margin-bottom: 90px;
    }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 






}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 

}




.topseller-box-links {
    background-color: #000000;
    height: 500px;
    position: relative;
    img{
    	width: 100%;
    	height: auto;
    }

    .description {
	    background-color: rgba( 277, 79, 67, 0.6);
	    bottom: 0;
	    padding-bottom: 30px;
	    padding-left: 30px;
	    padding-right: 30px;
	    padding-top: 30px;
	    position: absolute;
	    width: 100%;
	    a{
	    	color: #fff;
	    }
	}
}

.topseller-box-rechts{
	background-color: #000000;
    height: 235px;
    margin-bottom: 30px;
    position: relative;
    img{
    	width: 100%;
    	height: auto;
    }

    .description {
    	background-color: rgba( 277, 79, 67, 0.6);
	    bottom: 0;
	    padding-bottom: 20px;
	    padding-left: 30px;
	    padding-right: 30px;
	    padding-top: 20px;
	    position: absolute;
	    width: 100%;
	    a{
	    	color: #fff;
	    }
	}
}

.news-home{
	background-color: $infinitus-light-grey;
	.newsbox{
		background-color: #fff;
		padding: 20px;
		img{
			width: 100%;
			height: auto;
		}
		h3{
			padding-top: 0px;
			margin-top: 0px;
		}
	}
}

.home-leistungen{
	text-align: center;
	margin-top: 0px;
	.icon{
		i{
			margin-top: 50px;
			color: $infinitus-petrol;
			display: block;
			font-size: 60px;
			margin-bottom: 30px;
		}
	}
	h3{

	}
	p{

	}
	.cta{
		a{
			border: 2px solid $infinitus-red;
			width: 150px;
			padding-bottom: 5px;
			padding-top: 5px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			color: $infinitus-red;
			text-decoration: none;
			margin-top: 25px;
		}
	}
}

.unterseite, .section{
	margin-top: 0px;
	padding-bottom: 80px;
	h1{
		text-align: center;
		font-size: 36px;
		font-weight: 700;
	}
	ul{
		li{
			color: $infinitus-grey;
		}
	}
}


.unterseite{
	margin-top: 100px;
}

.unterseite-content{
	img{
		width: 100%;
		height: auto;
	}
}

.leistungen-box{
	border: 1px solid $infinitus-red;
	margin-top: 50px;
	margin-bottom: 100px;
	min-height: 310px;
	h2{
		text-align: left;
		margin-top: 0px;
		font-size: 18px;
	}
	.leistung-box-icon{
		background-color: $infinitus-red;
	    border-bottom-left-radius: 50%;
	    border-bottom-right-radius: 50%;
	    border-top-left-radius: 50%;
	    border-top-right-radius: 50%;
	    height: 80px;
	    margin-left: auto;
	    margin-top: -40px;
	    margin-right: auto;
	    width: 80px;
	    position: relative;
	    i{ 
	    	color: #fff;
	    	@include center();
	    	font-size: 30px;
	    }
	}
	.leistung-box-content{
		padding-left: 15px;
		padding-top: 10px;
		padding-bottom: 50px;
		padding-right: 15px;

	}
}

.produkte-box{
	border: 1px solid $infinitus-light-grey;
	margin-bottom: 30px;
	img{
		width: 100%;
		height: auto;
	}
	h2{
		font-size: 22px;
		text-align: left;
		padding-left: 20px;
		text-transform: uppercase;
	}
	p{
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 0px;
		padding-bottom: 20px;


	}
	.cta-infinitus{
		border: 1px solid $infinitus-red;
		width: auto;
		margin-left: auto;
		margin-right: auto;
		font-size: 14px;
		text-align: center;
		color: $infinitus-red;
		text-transform: uppercase;
		padding-top: 10px;
		padding-bottom: 10px;
		display: block;
		margin-top: 20px;
	}
}

.produkt-detail{
	margin-top: 110px;
	thead{
		th{
			width: 30%;
		}
	}
	h2{
		text-align: left;
    	font-size: 38px;
    	margin-bottom: 30px;
    	color: $infinitus-dark-grey;
	}
	img{
		width: 100%;
		height: auto;
	}
	.produktbeschreibung-tabelle{
		margin-top: 88px;
	}
	.produkt-beschreibung{
		margin-top: 20px;
		strong{
			font-size: 20px;
		}
		ul{
			li{
				padding-bottom: 15px;
				color: $infinitus-grey;
			}
		}
	}

}


.submit-home {
    display: block;
    border: 1px solid $infinitus-red;
    width: 200px;
    text-align: center;
    color: $infinitus-red;
    text-transform: uppercase;
    height: 65px;
}

.footer-submit {
    display: block;
    border: 1px solid #fff;
    width: 200px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    height: 65px;
  input{
  	color: #fff!important;
  }
}


/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 

	.unterseite{
		margin-top: 0px;
	}

	.unterseite, .section{
	margin-top: 100px;
	padding-bottom: 80px;
	h1{
		text-align: center;
		font-size: 36px;
		font-weight: 700;
	}
	ul{
		li{
			color: $infinitus-grey;
		}
	}
}


.home-leistungen{
	text-align: center;
	margin-top: 70px;
	.icon{
		i{
			margin-top:10px;
			color: $infinitus-petrol;
			display: block;
			font-size: 60px;
			margin-bottom: 30px;
		}
	}
	h3{

	}
	p{

	}
	.cta{
		a{
			border: 2px solid $infinitus-red;
			width: 150px;
			padding-bottom: 5px;
			padding-top: 5px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			color: $infinitus-red;
			text-decoration: none;
			margin-top: 25px;
		}
	}
}


.produkte-box{
	.cta-infinitus{
		width: 300px;
		font-size: 20px;
	}
}


.leistungen-box{
	border: 1px solid $infinitus-red;
	margin-top: 50px;
	margin-bottom: 100px;
	min-height: 310px;
	h2{
		text-align: left;
		margin-top: 0px;
		font-size: 22px;
	}
	.leistung-box-icon{
		background-color: $infinitus-red;
	    border-bottom-left-radius: 50%;
	    border-bottom-right-radius: 50%;
	    border-top-left-radius: 50%;
	    border-top-right-radius: 50%;
	    height: 80px;
	    margin-left: -40px;
	    margin-top: -40px;
	    width: 80px;
	    position: relative;
	    i{ 
	    	color: #fff;
	    	@include center();
	    	font-size: 30px;
	    }
	}
	.leistung-box-content{
		padding-left: 50px;
		padding-top: 10px;
		padding-bottom: 50px;
		padding-right: 37px;

	}
}



}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 






}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 

}

.nf-field{
	input{
		background-color: $infinitus-light-grey;
		border: 0px;
		padding: 20px;
	}
	textarea{
		background-color: $infinitus-light-grey;
		border: 0px;
		padding: 20px;
		margin-top: -300px;
		height: 340px!important;
	}

	.textarea-wrap {
    	margin-top: -277px;
	}


}

	.nf-error-msg.nf-error-field-errors {
		display: none!important;
	}

	.email-container{
		.nf-error-msg.nf-error-required-error{
			display: none;
		}
	}

	.firstname-container{
		.nf-error-msg.nf-error-required-error{
			display: none;
		}
	}


	.textbox-container{
		.nf-error-msg.nf-error-required-error{
			display: none;
		}
	}

.submit-home{
	float: right;
	input{
		border: 2px $infinitus-red !important;
		background-color: #fff;

	}
}


.footer{
	input{
		color: $infinitus-dark-grey;
	}
	.footer-submit {
		input{
			border: 2px $infinitus-red !important;
			background-color: rgba( 255, 255, 255, 0.0)!important;
		}
	}

}